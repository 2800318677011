import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class  ClientMasteryService
{
    private _data: BehaviorSubject<any> = new BehaviorSubject(null);
    
  
   
    private baseUrlCashFlex = environment.apiUrlCashFlex;
    constructor(private _httpClient: HttpClient)
    {
    }


    getProductDetails(linkId: string): Observable<any> {
      const headers = { 
       
      };

      const url = `${this.baseUrlCashFlex}get_direct_payment_link/${linkId}`;
      return this._httpClient.get(url, { headers: headers });
    }


    createPayment(data: any): Observable<any> {
      const headers = { 
        'Content-Type': 'application/json'
      };

      return this._httpClient.post<any>(`${this.baseUrlCashFlex}create_payment_link_transaction`, data, { headers });
    }
    

}

import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {

  public currentRoute: string;

  constructor(private router: Router){
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // The route changed
        this.currentRoute = window.location.pathname.split("?")[0];
      }
    });
  }

  ngOnInit(): void {
    this.currentRoute = window.location.pathname.split("?")[0];
  }
}

import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { ClientMasteryService } from './clientmastery.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-clientmastery',
  templateUrl: './clientmastery.component.html',
  styleUrls: ['./clientmastery.component.less']
})
export class ClientmasteryComponent implements OnInit {

  isSmallScreen = false;
  public customerFormGroup: FormGroup;

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = "0020101021228580011ph.ppmi.p2m0111CUOBPHM2XXX04179002201613239548705033015204601653036085406100.005802PH5917VIP BILLS PAYMENT6011METROMANILA6304A3F1";
  
  constructor(private modalService: NgbModal,private route: ActivatedRoute, private clientMasteryService :ClientMasteryService,
    private store: AngularFirestore, ) { 
    
  }
  clientId: string;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallScreen = window.innerWidth <= 1600;
  }

  ngOnInit() {
    this.isSmallScreen = window.innerWidth <= 1600;
    this.route.paramMap.subscribe(params => {
      this.clientId = params.get('id');
      this.initCustomerFormGroup(this.clientId);
    });

    this.store.collection('phpay_direct_link_status').doc(this.paymentData.account_number).valueChanges().subscribe((val: any) => {
     
      if(val.paid == true){
          //redirect
          this.redirectExternalUrl(this.productData.success_url);
      }
      
    });
  }

  redirectExternalUrl(url: string) {
    window.location.href = url;
  }
  

  initCustomerFormGroup(clientId: string){
    this.customerFormGroup = new FormGroup({
      first_name: new FormControl(null, [Validators.required]),
      last_name: new FormControl(null, [Validators.required]),
      mobile_number: new FormControl(null, [Validators.required]),
      email_address: new FormControl(null, [Validators.required, Validators.email]),
      address: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      postal_code: new FormControl(null, [Validators.required]),
      country: new FormControl("Philippines"),
      link_id: new FormControl(clientId, [Validators.required]),
    });
    this.getProductDetails();
  }

  productData : any;

  getProductDetails(){
    this.clientMasteryService.getProductDetails(this.clientId).subscribe(response => {
      this.productData = response.response_data;
    },error=>{
    });
  }
  isLoading = false;
  paymentData :any;
  onSubmit(): void {
    if (this.customerFormGroup.valid) {

      this.isLoading = true;
      // user clicked Yes, make the API call
      this.clientMasteryService.createPayment(this.customerFormGroup.value).subscribe(
              response => {
                // handle success case
                this.isLoading = false;
                if(response.response_result === "0001") {
                  this.paymentData = response.response_data;
                  // reset the form
                  this.ngOnInit();
                  this.customerFormGroup.reset();
                  this.customerFormGroup.markAsUntouched();
                  this.customerFormGroup.markAsPristine();
                  this.customerFormGroup = new FormGroup({
                    first_name: new FormControl(null, [Validators.required]),
                    last_name: new FormControl(null, [Validators.required]),
                    mobile_number: new FormControl(null, [Validators.required]),
                    email_address: new FormControl(null, [Validators.required, Validators.email]),
                    address: new FormControl(null, [Validators.required]),
                    city: new FormControl(null, [Validators.required]),
                    postal_code: new FormControl(null, [Validators.required]),
                    country: new FormControl("Philippines"),
                    link_id: new FormControl(this.clientId, [Validators.required]),
                  });
                  this.openQRLink();


                } else if(response.response_result === "0002") {
                  this.isLoading = false;
                  alert(response.response_description)
                  // this.dialog.open(ErrorDialogComponent, {
                  //   data: {
                  //     content: response.response_description,
                  //     header: "Transaction error"
                  //   }
                  // });
                  // handle failure case
                }
              },
              error => {
                this.isLoading = false;
                alert("We're unable to initiate the payment process at the moment. Please try again later. If the issue continues, contact our support team for help.")
                // this.dialog.open(ErrorDialogComponent, {
                //   data: {
                //     content: error.error.response_description,
                //     header: "Transaction error"
                //   }
                // });
              }
            );
      }else{
        this.touchAllControls();
      }
  }

  touchAllControls(): void {
    Object.keys(this.customerFormGroup.controls).forEach(field => {
      const control = this.customerFormGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  qrLinkModalRef: NgbModalRef;
  @ViewChild('qrlinkModal') qrlinkModal: TemplateRef<any>;
  openQRLink() {
    if (this.qrlinkModal) {
      this.qrLinkModalRef = this.modalService.open(this.qrlinkModal, {
        centered: true,
        size: 'md',
        backdrop: 'static',  // Disables closing the modal by clicking on the backdrop
        keyboard: false       // Disables closing the modal with the ESC key
      });
    } else {
      console.error('Modal template not loaded');
    }
  }
  

  renderImage(base64){
    return `data:image/png;base64,${base64}`;
  }

  
  convertToDate(timestamp){

    // Convert to a Date object
    var date = new Date(timestamp);

    // Format the date and time
    var formattedDate = date.toLocaleString(); // You can customize this format as needed

    return (formattedDate); // Output will be in the format "MM/DD/YYYY, HH:MM:SS AM/PM" based on the system's locale

  }

  countries = [
    {"code": "AF", "name": "Afghanistan"},
    {"code": "AL", "name": "Albania"},
    {"code": "DZ", "name": "Algeria"},
    {"code": "AS", "name": "American Samoa"},
    {"code": "AD", "name": "Andorra"},
    {"code": "AO", "name": "Angola"},
    {"code": "AI", "name": "Anguilla"},
    {"code": "AQ", "name": "Antarctica"},
    {"code": "AG", "name": "Antigua and Barbuda"},
    {"code": "AR", "name": "Argentina"},
    {"code": "AM", "name": "Armenia"},
    {"code": "AW", "name": "Aruba"},
    {"code": "AU", "name": "Australia"},
    {"code": "AT", "name": "Austria"},
    {"code": "AZ", "name": "Azerbaijan"},
    {"code": "BS", "name": "Bahamas"},
    {"code": "BH", "name": "Bahrain"},
    {"code": "BD", "name": "Bangladesh"},
    {"code": "BB", "name": "Barbados"},
    {"code": "BY", "name": "Belarus"},
    {"code": "BE", "name": "Belgium"},
    {"code": "BZ", "name": "Belize"},
    {"code": "BJ", "name": "Benin"},
    {"code": "BM", "name": "Bermuda"},
    {"code": "BT", "name": "Bhutan"},
    {"code": "BO", "name": "Bolivia"},
    {"code": "BA", "name": "Bosnia and Herzegovina"},
    {"code": "BW", "name": "Botswana"},
    {"code": "BR", "name": "Brazil"},
    {"code": "IO", "name": "British Indian Ocean Territory"},
    {"code": "VG", "name": "British Virgin Islands"},
    {"code": "BN", "name": "Brunei"},
    {"code": "BG", "name": "Bulgaria"},
    {"code": "BF", "name": "Burkina Faso"},
    {"code": "BI", "name": "Burundi"},
    {"code": "KH", "name": "Cambodia"},
    {"code": "CM", "name": "Cameroon"},
    {"code": "CA", "name": "Canada"},
    {"code": "CV", "name": "Cape Verde"},
    {"code": "KY", "name": "Cayman Islands"},
    {"code": "CF", "name": "Central African Republic"},
    {"code": "TD", "name": "Chad"},
    {"code": "CL", "name": "Chile"},
    {"code": "CN", "name": "China"},
    {"code": "CX", "name": "Christmas Island"},
    {"code": "CC", "name": "Cocos Islands"},
    {"code": "CO", "name": "Colombia"},
    {"code": "KM", "name": "Comoros"},
    {"code": "CK", "name": "Cook Islands"},
    {"code": "CR", "name": "Costa Rica"},
    {"code": "HR", "name": "Croatia"},
    {"code": "CU", "name": "Cuba"},
    {"code": "CW", "name": "Curacao"},
    {"code": "CY", "name": "Cyprus"},
    {"code": "CZ", "name": "Czech Republic"},
    {"code": "CD", "name": "Democratic Republic of the Congo"},
    {"code": "DK", "name": "Denmark"},
    {"code": "DJ", "name": "Djibouti"},
    {"code": "DM", "name": "Dominica"},
    {"code": "DO", "name": "Dominican Republic"},
    {"code": "TL", "name": "East Timor"},
    {"code": "EC", "name": "Ecuador"},
    {"code": "EG", "name": "Egypt"},
    {"code": "SV", "name": "El Salvador"},
    {"code": "GQ", "name": "Equatorial Guinea"},
    {"code": "ER", "name": "Eritrea"},
    {"code": "EE", "name": "Estonia"},
    {"code": "ET", "name": "Ethiopia"},
    {"code": "FK", "name": "Falkland Islands"},
    {"code": "FO", "name": "Faroe Islands"},
    {"code": "FJ", "name": "Fiji"},
    {"code": "FI", "name": "Finland"},
    {"code": "FR", "name": "France"},
    {"code": "PF", "name": "French Polynesia"},
    {"code": "GA", "name": "Gabon"},
    {"code": "GM", "name": "Gambia"},
    {"code": "GE", "name": "Georgia"},
    {"code": "DE", "name": "Germany"},
    {"code": "GH", "name": "Ghana"},
    {"code": "GI", "name": "Gibraltar"},
    {"code": "GR", "name": "Greece"},
    {"code": "GL", "name": "Greenland"},
    {"code": "GD", "name": "Grenada"},
    {"code": "GU", "name": "Guam"},
    {"code": "GT", "name": "Guatemala"},
    {"code": "GG", "name": "Guernsey"},
    {"code": "GN", "name": "Guinea"},
    {"code": "GW", "name": "Guinea-Bissau"},
    {"code": "GY", "name": "Guyana"},
    {"code": "HT", "name": "Haiti"},
    {"code": "HN", "name": "Honduras"},
    {"code": "HK", "name": "Hong Kong"},
    {"code": "HU", "name": "Hungary"},
    {"code": "IS", "name": "Iceland"},
    {"code": "IN", "name": "India"},
    {"code": "ID", "name": "Indonesia"},
    {"code": "IR", "name": "Iran"},
    {"code": "IQ", "name": "Iraq"},
    {"code": "IE", "name": "Ireland"},
    {"code": "IM", "name": "Isle of Man"},
    {"code": "IL", "name": "Israel"},
    {"code": "IT", "name": "Italy"},
    {"code": "CI", "name": "Ivory Coast"},
    {"code": "JM", "name": "Jamaica"},
    {"code": "JP", "name": "Japan"},
    {"code": "JE", "name": "Jersey"},
    {"code": "JO", "name": "Jordan"},
    {"code": "KZ", "name": "Kazakhstan"},
    {"code": "KE", "name": "Kenya"},
    {"code": "KI", "name": "Kiribati"},
    {"code": "KW", "name": "Kuwait"},
    {"code": "KG", "name": "Kyrgyzstan"},
    {"code": "LA", "name": "Laos"},
    {"code": "LV", "name": "Latvia"},
    {"code": "LB", "name": "Lebanon"},
    {"code": "LS", "name": "Lesotho"},
    {"code": "LR", "name": "Liberia"},
    {"code": "LY", "name": "Libya"},
    {"code": "LI", "name": "Liechtenstein"},
    {"code": "LT", "name": "Lithuania"},
    {"code": "LU", "name": "Luxembourg"},
    {"code": "MO", "name": "Macao"},
    {"code": "MK", "name": "Macedonia"},
    {"code": "MG", "name": "Madagascar"},
    {"code": "MW", "name": "Malawi"},
    {"code": "MY", "name": "Malaysia"},
    {"code": "MV", "name": "Maldives"},
    {"code": "ML", "name": "Mali"},
    {"code": "MT", "name": "Malta"},
    {"code": "MH", "name": "Marshall Islands"},
    {"code": "MR", "name": "Mauritania"},
    {"code": "MU", "name": "Mauritius"},
    {"code": "YT", "name": "Mayotte"},
    {"code": "MX", "name": "Mexico"},
    {"code": "FM", "name": "Micronesia"},
    {"code": "MD", "name": "Moldova"},
    {"code": "MC", "name": "Monaco"},
    {"code": "MN", "name": "Mongolia"},
    {"code": "ME", "name": "Montenegro"},
    {"code": "MS", "name": "Montserrat"},
    {"code": "MA", "name": "Morocco"},
    {"code": "MZ", "name": "Mozambique"},
    {"code": "MM", "name": "Myanmar"},
    {"code": "NA", "name": "Namibia"},
    {"code": "NR", "name": "Nauru"},
    {"code": "NP", "name": "Nepal"},
    {"code": "NL", "name": "Netherlands"},
    {"code": "AN", "name": "Netherlands Antilles"},
    {"code": "NC", "name": "New Caledonia"},
    {"code": "NZ", "name": "New Zealand"},
    {"code": "NI", "name": "Nicaragua"},
    {"code": "NE", "name": "Niger"},
    {"code": "NG", "name": "Nigeria"},
    {"code": "NU", "name": "Niue"},
    {"code": "NF", "name": "Norfolk Island"},
    {"code": "KP", "name": "North Korea"},
    {"code": "MP", "name": "Northern Mariana Islands"},
    {"code": "NO", "name": "Norway"},
    {"code": "OM", "name": "Oman"},
    {"code": "PK", "name": "Pakistan"},
    {"code": "PW", "name": "Palau"},
    {"code": "PS", "name": "Palestine"},
    {"code": "PA", "name": "Panama"},
    {"code": "PG", "name": "Papua New Guinea"},
    {"code": "PY", "name": "Paraguay"},
    {"code": "PE", "name": "Peru"},
    {"code": "PH", "name": "Philippines"},
    {"code": "PN", "name": "Pitcairn"},
    {"code": "PL", "name": "Poland"},
    {"code": "PT", "name": "Portugal"},
    {"code": "PR", "name": "Puerto Rico"},
    {"code": "QA", "name": "Qatar"},
    {"code": "CG", "name": "Republic of the Congo"},
    {"code": "RE", "name": "Reunion"},
    {"code": "RO", "name": "Romania"},
    {"code": "RU", "name": "Russia"},
    {"code": "RW", "name": "Rwanda"},
    {"code": "BL", "name": "Saint Barthelemy"},
    {"code": "SH", "name": "Saint Helena"},
    {"code": "KN", "name": "Saint Kitts and Nevis"},
    {"code": "LC", "name": "Saint Lucia"},
    {"code": "MF", "name": "Saint Martin"},
    {"code": "PM", "name": "Saint Pierre and Miquelon"},
    {"code": "VC", "name": "Saint Vincent and the Grenadines"},
    {"code": "WS", "name": "Samoa"},
    {"code": "SM", "name": "San Marino"},
    {"code": "ST", "name": "Sao Tome and Principe"},
    {"code": "SA", "name": "Saudi Arabia"},
    {"code": "SN", "name": "Senegal"},
    {"code": "RS", "name": "Serbia"},
    {"code": "SC", "name": "Seychelles"},
    {"code": "SL", "name": "Sierra Leone"},
    {"code": "SG", "name": "Singapore"},
    {"code": "SX", "name": "Sint Maarten"},
    {"code": "SK", "name": "Slovakia"},
    {"code": "SI", "name": "Slovenia"},
    {"code": "SB", "name": "Solomon Islands"},
    {"code": "SO", "name": "Somalia"},
    {"code": "ZA", "name": "South Africa"},
    {"code": "KR", "name": "South Korea"},
    {"code": "SS", "name": "South Sudan"},
    {"code": "ES", "name": "Spain"},
    {"code": "LK", "name": "Sri Lanka"},
    {"code": "SD", "name": "Sudan"},
    {"code": "SR", "name": "Suriname"},
    {"code": "SJ", "name": "Svalbard and Jan Mayen"},
    {"code": "SZ", "name": "Swaziland"},
    {"code": "SE", "name": "Sweden"},
    {"code": "CH", "name": "Switzerland"},
    {"code": "SY", "name": "Syria"},
    {"code": "TW", "name": "Taiwan"},
    {"code": "TJ", "name": "Tajikistan"},
    {"code": "TZ", "name": "Tanzania"},
    {"code": "TH", "name": "Thailand"},
    {"code": "TG", "name": "Togo"},
    {"code": "TK", "name": "Tokelau"},
    {"code": "TO", "name": "Tonga"},
    {"code": "TT", "name": "Trinidad and Tobago"},
    {"code": "TN", "name": "Tunisia"},
    {"code": "TR", "name": "Turkey"},
    {"code": "TM", "name": "Turkmenistan"},
    {"code": "TC", "name": "Turks and Caicos Islands"},
    {"code": "TV", "name": "Tuvalu"},
    {"code": "VI", "name": "U.S. Virgin Islands"},
    {"code": "UG", "name": "Uganda"},
    {"code": "UA", "name": "Ukraine"},
    {"code": "AE", "name": "United Arab Emirates"},
    {"code": "GB", "name": "United Kingdom"},
    {"code": "US", "name": "United States"},
    {"code": "UY", "name": "Uruguay"},
    {"code": "UZ", "name": "Uzbekistan"},
    {"code": "VU", "name": "Vanuatu"},
    {"code": "VA", "name": "Vatican"},
    {"code": "VE", "name": "Venezuela"},
    {"code": "VN", "name": "Vietnam"},
    {"code": "WF", "name": "Wallis and Futuna"},
    {"code": "EH", "name": "Western Sahara"},
    {"code": "YE", "name": "Yemen"},
    {"code": "ZM", "name": "Zambia"},
    {"code": "ZW", "name": "Zimbabwe"}
  ];
  
}

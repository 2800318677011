<div *ngIf="isLoading" class="overlay">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>

<div class="container" style="height: 99%; width: 99%; display: flex;">
    <div class="header">
        <div class="d-flex p-5 d-flex">
            <div>
                <img [src]="productData.logo" alt="" class="logo">
            </div>
            <div class="d-flex align-items-center p-3">
                <p class="h3" style="font-weight: 800; color: #c54f2b;">{{productData.company_name}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12">
                <!-- <div [ngClass]="{'custom-padding': !isSmallScreen, 'small-padding': isSmallScreen}"> -->
                <div class="small-padding">
                    
                    <div class="form" [formGroup]="customerFormGroup">
                        <p class="h5" style="color: #B25733; font-weight: 700;">CUSTOMER INFORMATION</p>
    
                        <div class="row">
                            <div class="col-6">
                                <div class="mb-3">
                                    <label for="first_name" class="form-label font-weight-bold">First Name <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" id="first_name" placeholder="John" formControlName="first_name"
                                    tabindex="1" maxlength="50"
                                    [class.is-invalid]="customerFormGroup.get('first_name').touched && customerFormGroup.get('first_name').invalid">
                                </div>
                                <div class="mb-3">
                                    <label for="mobile_number" class="form-label font-weight-bold">Mobile Number <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" id="mobile_number" placeholder="09XXXXXXXXX" formControlName="mobile_number"
                                    tabindex="3" maxlength="11"
                                    [class.is-invalid]="customerFormGroup.get('first_name').touched && customerFormGroup.get('first_name').invalid">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="mb-3">
                                    <label for="last_name" class="form-label font-weight-bold">Last Name <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" id="last_name" placeholder="Doe" formControlName="last_name"
                                    tabindex="2" maxlength="50"
                                    [class.is-invalid]="customerFormGroup.get('first_name').touched && customerFormGroup.get('first_name').invalid">
                                </div>
    
                                <div class="mb-3">
                                    <label for="email_address" class="form-label font-weight-bold">Email Address </label>
                                    <input type="email_address" class="form-control" id="email" placeholder="abc@gmail.com" formControlName="email_address"
                                    tabindex="4" maxlength="50"
                                    [class.is-invalid]="customerFormGroup.get('email_address').touched && customerFormGroup.get('email_address').invalid">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="mb-3">
                                    <label for="address" class="form-label font-weight-bold">Address</label>
                                    <input type="text" class="form-control" id="address" placeholder="Address" formControlName="address"
                                    tabindex="5" maxlength="800"
                                    [class.is-invalid]="customerFormGroup.get('address').touched && customerFormGroup.get('address').invalid">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="mb-3">
                                    <label for="city" class="form-label font-weight-bold">City/District</label>
                                    <input type="text" class="form-control" id="city" placeholder="City/District" formControlName="city"
                                    tabindex="6" maxlength="50"
                                    [class.is-invalid]="customerFormGroup.get('city').touched && customerFormGroup.get('city').invalid">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="mb-3">
                                    <label for="postal_code" class="form-label font-weight-bold">Postal Code</label>
                                    <input type="text" class="form-control" id="postal_code" placeholder="Postal Code" formControlName="postal_code"
                                    tabindex="7" maxlength="10"
                                    [class.is-invalid]="customerFormGroup.get('postal_code').touched && customerFormGroup.get('postal_code').invalid">
                                </div>
                            </div>
                            <div class="col-12">
                                <label for="country" class="form-label font-weight-bold">Country</label>
                                <select id="country" class="form-control" aria-label="Country select" formControlName="country" 
                                tabindex="8">
                                    <option *ngFor="let country of countries" [value]="country.name">{{ country.name }}</option>
                                </select>
                              
                                <div class="w-100 d-flex justify-content-end mt-5 mb-5">
                                    <button *ngIf="!isLoading" type="button" class="btn btn-success" style="width: 150px;" (click)="onSubmit()" 
                                    tabindex="9">Continue</button>
                                    <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
                                </div>
                            </div>                            
                        </div>
                    </div>
                      
                </div>
                
            </div>
            <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 pb-5">
                <!-- <div [ngClass]="{'custom-padding2': !isSmallScreen, 'small-padding': isSmallScreen}"> -->
                <div class="small-padding">
                    <p class="h5 mb-1" style="font-weight: 700; color: #B25733;">Payment Link Details</p>
                    <p>
                        <span style="font-weight: 500;">Link ID:</span> <br> {{clientId}}
                    </p>

                    <!-- <p class="h6 mb-1 mt-4" style="font-weight: 500;"><i class="fas fa-edit"></i>&nbsp; Description</p>
                    <p class="h6">
                        {{productData.product_name}}
                    </p>
                    -->
                    <hr> 

                    <p class="fs-5 mb-4 mt-4" style="font-weight: 600;">Order Summary</p>
                    <div class="d-flex justify-content-between">
                        <div>
                            <p class="h6 mb-1" style="font-weight: 500;">
                                {{productData.product_name}}
                            </p>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div>
                            <p class="h6" style="font-weight: 500;">
                                x1
                            </p>
                        </div>
                        <div class="d-flex">
                            <p class="h6" style="font-weight: 500;">
                                PHP {{productData.amount | number:'1.2-2'}}
                            </p>
                        </div>
                    </div>

                    <hr>

                    <div class="d-flex justify-content-end">
                        <div class="d-flex justify-content-between w-100">
                          <span class="fs-6" style="font-weight: 500;">
                            Subtotal
                          </span>
                          <span class="fs-6" style="font-weight: 500;">
                            PHP {{productData.amount | number:'1.2-2'}}
                          </span>
                        </div>
                    </div>                      

                    <hr>

                    <div class="d-flex justify-content-end">
                        <div class="d-flex justify-content-between w-100">
                          <span class="fs-6 font-weight-bold">
                            Amount to Pay
                          </span>
                          <span class="h5 font-weight-bold">
                            PHP {{productData.amount | number:'1.2-2'}}
                          </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <svg id="clouds" alt="Gray Clouds Background" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677" viewBox="0 0 2611.084 485.677">
        <path id="Path_39" data-name="Path 39" d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z" transform="translate(142.69 -634.312)" fill="#eee" />
      </svg>
</div>

<ng-template #qrlinkModal let-modal>
    <div class="p-4">
        <div class="d-flex flex-column">
            <div class="pl-3 pr-3">
                <h4 style="font-weight: 700;">QRPH Payment link</h4>
                <p class="h6 mb-0 pb-0">Please pay using the QR code provided to complete the process.</p>
            </div>
            <div class="d-flex flex-column justify-content-center">
                <img *ngIf="paymentData.qrph_code" [src]="renderImage(paymentData.qrph_code)" alt="QR Code" style="width: 300px;"/>
                    <!-- <div *ngIf="paymentData.qrph_code" class="text-2xl md:text-2xl font-extrabold tracking-tight leading-none" style="
                    margin-top: -20px;">{{paymentData.store_name}}</div>
                 -->
                <div class="d-flex justify-content-center">
                    <h4 class="font-weight-bolder" style="
                    margin-top: -30px;
                    font-size: medium;
                ">{{paymentData.store_name}}</h4>
                </div>
            </div>
            <div class="pl-5 pr-5 pt-3">
                <h5 class="mb-0 pb-0 text-muted">Account Number</h5>
                <h5>{{paymentData.account_number}}</h5>
                <hr>
                <h5 class="mb-0 pb-0 text-muted">Amount</h5>
                <h5>{{paymentData.amount_currency}} {{paymentData.amount | number:'1.2-2'}}</h5>
                <hr>
                <h5 class="mb-0 pb-0 text-muted">Partner Reference ID</h5>
                <h5>{{paymentData.partner_reference_id}}</h5>
                <hr>
                <h5 class="mb-0 pb-0 text-muted">Created Date</h5>
                <h5>{{convertToDate(paymentData.created_date)}}</h5>
                
            </div>
        </div>
    </div>
</ng-template>
export const environment = {
  production: false,
  apiUrlCashFlex : "https://gcf-api.vipco.io/api/v1/",
  // firebaseConfig: {
  //   apiKey: "AIzaSyCdnpoTUNqea0P7O2amn1ZdbV_zoQsXuJ0",
  //   authDomain: "vipcomegawallet-prod.firebaseapp.com",
  //   projectId: "vipcomegawallet-prod",
  //   storageBucket: "vipcomegawallet-prod.appspot.com",
  //   messagingSenderId: "69721755972",
  //   appId: "1:69721755972:web:b4fec8a3b62b6f78550711",
  //   measurementId: "G-VL63TTQVLL"
  // }

  firebaseConfig :{
    apiKey: "AIzaSyDV4oOlVMhBn_xcuEUAA7WJSH6Ku6aHaxU",
    authDomain: "vipcomegawallet.firebaseapp.com",
    databaseURL: "https://vipcomegawallet-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "vipcomegawallet",
    storageBucket: "vipcomegawallet.appspot.com",
    messagingSenderId: "896452778750",
    appId: "1:896452778750:web:681799d853d055b68323af",
    measurementId: "G-PW9ELQQF3C"
  }
};
